import React from 'react';
import {
  MDBFooter,
  MDBContainer,
  MDBIcon,
  MDBBtn
} from 'mdb-react-ui-kit';


export default function Footer() {
  return (
    <MDBFooter className='text-center text-white mt-2' style={{ backgroundColor: '#F6984E' }}>
      <MDBContainer className='pt-2'>
        <section className='mb-2'>
          
          <MDBBtn
            rippleColor="dark"
            color='link'
            floating
            size="lg"
            className='text-dark'
            href='https://www.instagram.com/arqua.difusion/?igshid=MzRlODBiNWFlZA%3D%3D'
            role='button'
          >
            <MDBIcon fab className='fa-instagram' size="2x" />
          </MDBBtn>

          <MDBBtn
            rippleColor="dark"
            color='link'
            floating
            size="lg"
            className='text-dark iconSize'
            href='https://www.youtube.com/@arqua-arqueologxsuruguay9425/about'
            role='button'
          >
            <MDBIcon fab className='fa-youtube' size="2x" />
          </MDBBtn>

          <MDBBtn
            rippleColor="dark"
            color='link'
            floating
            size="lg"
            className='text-dark'
            href='/Contact'
            role='button'
          >
           <MDBIcon far icon="envelope" size="2x" />
          </MDBBtn>
        </section>
      </MDBContainer>

      <div className='text-center text-dark p-3 small' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        © 2023 Copyright: Arquéologos del Uruguay Asociados
        <a href="/Condiciones" className='p-2'>Términos y Condiciones</a>
        <a className='text-dark small' href='https://gervasioriveiro.com'>
          @Gervasio Riveiro
        </a>
      </div>
    </MDBFooter>
  );
}