import MenuNav from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import { MDBContainer, MDBCard, MDBCardImage, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import Panteones_01 from '../assets/Panteones_01.jpg';
import Panteones_02 from '../assets/Panteones_02.jpg';
import Panteones_03 from '../assets/Panteones_03.jpg';
import Panteones_04 from '../assets/Panteones_04.jpg';
import Panteones_05 from '../assets/Panteones_05.jpg';
import Panteones_06 from '../assets/Panteones_06.jpg';


function Panteones () {


    return (
        <>
    <MenuNav />
    <MDBContainer className='containerHome containerHome d-flex flex-column align-items-center '>
    <h5 className="text-focus-in pt-3 text-center text-light mb-5">
        Panteones en Cerro Largo<br></br>
    </h5>
    <p className='text-light'>Elena Saccone</p>
    <MDBRow className='row-cols-1 row-cols-md-3 g-4'>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={Panteones_01} alt='...' position='top' className='rounded-0'/>
          <a href={Panteones_01} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={Panteones_02} alt='...' position='top' className='rounded-0'/>
          <a href={Panteones_02} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={Panteones_03} alt='...' position='top' className='rounded-0'/>
          <a href={Panteones_03} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={Panteones_04} alt='...' position='top' className='rounded-0'/>
          <a href={Panteones_04} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={Panteones_05} alt='...' position='top' className='rounded-0'/>
          <a href={Panteones_05} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={Panteones_06} alt='...' position='top' className='rounded-0'/>
          <a href={Panteones_06} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
    </MDBRow>
    </MDBContainer>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <Footer className='footerHistoria'/>
    </>
        
    )
    }
    
    export default Panteones;
    