import MenuNav from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import { MDBContainer, MDBCard, MDBCardImage, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import ConosLavalleja1 from '../assets/ConosLavalleja.jpg'
import ConosLavalleja2 from '../assets/ConosLavalleja2.jpg'
import ConosLavalleja3 from '../assets/ConosLavalleja3.jpg'
import ConosLavalleja4 from '../assets/ConosLavalleja4.jpg'
import ConosLavalleja5 from '../assets/ConosLavalleja5.jpg'



function ConosLavalleja () {


    return (
        <>
    <MenuNav />
    <MDBContainer className='containerHome containerHome d-flex flex-column align-items-center '>
    <h5 className="text-focus-in pt-3 text-center text-light mb-5">
        Proyecto: “Conos de piedra de Lavalleja”<br></br>
    </h5>
    <p className='small text-light text-center mb-5'>Estas estructuras fueron construidas en piedras locales con una técnica denominada “piedra seca”. Se localizan en zonas de serranía de Lavalleja (aunque también están presentes en otras regiones del país). Aún se desconoce con exactitud su origen y función. Fueron registrados en el marco de un proyecto a través del cual se buscó realizar una aproximación a su datación a partir de los liquenes que crecen sobre las rocas. Esta técnica, la liquenometría, permitió asignarles una antigüedad mínima de construcción entre los años 1774 a 1821, época que coincide aproximadamente con la fundación de la ciudad de Minas.</p>
    <MDBRow className='row-cols-1 row-cols-md-3 g-4'>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={ConosLavalleja1} alt='...' position='top' className='rounded-0'/>
          <a href={ConosLavalleja1} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={ConosLavalleja2} alt='...' position='top' className='rounded-0'/>
          <a href={ConosLavalleja2} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={ConosLavalleja3} alt='...' position='top' className='rounded-0'/>
          <a href={ConosLavalleja3} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={ConosLavalleja4} alt='...' position='top' className='rounded-0'/>
          <a href={ConosLavalleja4} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={ConosLavalleja5} alt='...' position='top' className='rounded-0'/>
          <a href={ConosLavalleja5} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
    </MDBRow>
    </MDBContainer>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <Footer className='footerHistoria'/>
    </>
        
    )
    }
    
    export default ConosLavalleja;
    