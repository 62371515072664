import React, { useState } from 'react';
import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import LucasFoto from '../assets/Lucas.jpg';
import userAnon from '../assets/userAnon.png';
import {MDBContainer, MDBTable, MDBTableHead, MDBTableBody, MDBIcon,  MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane } from 'mdb-react-ui-kit';

export default function Comisiones() {

    const [basicActive, setBasicActive] = useState('tab1');

    const handleBasicClick = (value: string) => {
      if (value === basicActive) {
        return;
      }
  
      setBasicActive(value);
    };

  return (
    <>

    <Navbar/>
    <MDBContainer className='containerHome bg-light rounded square border border-1'>
        <MDBTabs className='mb-3 mt-3 d-flex flex-row justify-content-center'>
            <MDBTabsItem>
            <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'} className='text-dark'>
                Comisión Directiva
            </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
            <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'} className='text-dark'>
                Comisión Fiscal
            </MDBTabsLink>
            </MDBTabsItem>
        </MDBTabs>
        <MDBTabsContent>
        <MDBTabsPane show={basicActive === 'tab1'}>   
            <a className='text-muted mb-0 text-light' href='mailto:arqueologos.uruguay@gmail.com'>
                <MDBIcon far icon="envelope" size="lg"className='mx-2 text-light'/>arqueologos.uruguay@gmail.com
            </a>
            <MDBTable align='middle'>
                <MDBTableHead>
                    <tr>
                    <th scope='col'>Nombre</th>
                    <th scope='col'>Rol</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    <tr>
                        <td>
                            <div className='d-flex align-items-center'>
                            <img
                                src={LucasFoto}
                                alt=''
                                style={{ width: '45px', height: '45px' }}
                                className='rounded-circle'
                            />
                            <div className='ms-3'>
                                <p className='fw-bold mb-1'>Lucas Prieto</p>
                            </div>
                            </div>
                        </td>
                        <td>
                            <p className='fw-normal mb-1'>Presidente</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='d-flex align-items-center'>
                            <img
                                src={userAnon}
                                alt=''
                                style={{ width: '45px', height: '45px' }}
                                className='rounded-circle'
                            />
                            <div className='ms-3'>
                                <p className='fw-bold mb-1'>Carolina Delgado</p>
                            </div>
                            </div>
                        </td>
                        <td>
                            <p className='fw-normal mb-1'>Vicepresidenta</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='d-flex align-items-center'>
                            <img
                                src={userAnon}
                                alt=''
                                style={{ width: '45px', height: '45px' }}
                                className='rounded-circle'
                            />
                            <div className='ms-3'>
                                <p className='fw-bold mb-1'>Sandra Pintos</p>
                            </div>
                            </div>
                        </td>
                        <td>
                            <p className='fw-normal mb-1'>Secretaria</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='d-flex align-items-center'>
                            <img
                                src={userAnon}
                                alt=''
                                style={{ width: '45px', height: '45px' }}
                                className='rounded-circle'
                            />
                            <div className='ms-3'>
                                <p className='fw-bold mb-1'>Celeste Martínez</p>
                            </div>
                            </div>
                        </td>
                        <td>
                            <p className='fw-normal mb-1'>Vocal</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='d-flex align-items-center'>
                            <img
                                src={userAnon}
                                alt=''
                                style={{ width: '45px', height: '45px' }}
                                className='rounded-circle'
                            />
                            <div className='ms-3'>
                                <p className='fw-bold mb-1'>Aparicio Arcaus</p>
                                <p className='text-muted mb-0'>tesoreria.arqua@gmail.com</p>
                            </div>
                            </div>
                        </td>
                        <td>
                            <p className='fw-normal mb-1'>Tesorero</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='d-flex align-items-center'>
                            <img
                                src={userAnon}
                                alt=''
                                style={{ width: '45px', height: '45px' }}
                                className='rounded-circle'
                            />
                            <div className='ms-3'>
                                <p className='fw-bold mb-1'>Patricia Mut</p>
                            </div>
                            </div>
                        </td>
                        <td>
                            <p className='fw-normal mb-1'>1er Suplente</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='d-flex align-items-center'>
                            <img
                                src={userAnon}
                                alt=''
                                style={{ width: '45px', height: '45px' }}
                                className='rounded-circle'
                            />
                            <div className='ms-3'>
                                <p className='fw-bold mb-1'>Paula Larghero</p>
                            </div>
                            </div>
                        </td>
                        <td>
                            <p className='fw-normal mb-1'>Suplente</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='d-flex align-items-center'>
                            <img
                                src={userAnon}
                                alt=''
                                style={{ width: '45px', height: '45px' }}
                                className='rounded-circle'
                            />
                            <div className='ms-3'>
                                <p className='fw-bold mb-1'>Elena Vallvé</p>
                            </div>
                            </div>
                        </td>
                        <td>
                            <p className='fw-normal mb-1'>Suplente</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='d-flex align-items-center'>
                            <img
                                src={userAnon}
                                alt=''
                                style={{ width: '45px', height: '45px' }}
                                className='rounded-circle'
                            />
                            <div className='ms-3'>
                                <p className='fw-bold mb-1'>Eugenia Villarmarzo</p>
                            </div>
                            </div>
                        </td>
                        <td>
                            <p className='fw-normal mb-1'>Suplente</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='d-flex align-items-center'>
                            <img
                                src={userAnon}
                                alt=''
                                style={{ width: '45px', height: '45px' }}
                                className='rounded-circle'
                            />
                            <div className='ms-3'>
                                <p className='fw-bold mb-1'>Carlos Marin </p>
                            </div>
                            </div>
                        </td>
                        <td>
                            <p className='fw-normal mb-1'>Suplente</p>
                        </td>
                    </tr>
                </MDBTableBody>
            </MDBTable>
        </MDBTabsPane>
        <MDBTabsPane show={basicActive === 'tab2'}>
        <MDBTable align='middle'>
            <MDBTableHead>
                <tr>
                    <th scope='col'>Nombre</th>
                    <th scope='col'>Rol</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody>
                <tr>
                    <td>
                        <div className='d-flex align-items-center'>
                        <img
                            src={userAnon}
                            alt=''
                            style={{ width: '45px', height: '45px' }}
                            className='rounded-circle'
                        />
                        <div className='ms-3'>
                            <p className='fw-bold mb-1'>Moira Sotelo</p>
                        </div>
                        </div>
                    </td>
                    <td>
                        <p className='fw-normal mb-1'>Presidenta</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className='d-flex align-items-center'>
                        <img
                            src={userAnon}
                            alt=''
                            style={{ width: '45px', height: '45px' }}
                            className='rounded-circle'
                        />
                        <div className='ms-3'>
                            <p className='fw-bold mb-1'>Camila Gianotti</p>
                        </div>
                        </div>
                    </td>
                    <td>
                        <p className='fw-normal mb-1'>Titular</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className='d-flex align-items-center'>
                        <img
                            src={userAnon}
                            alt=''
                            style={{ width: '45px', height: '45px' }}
                            className='rounded-circle'
                        />
                        <div className='ms-3'>
                            <p className='fw-bold mb-1'>Leticia García</p>
                        </div>
                        </div>
                    </td>
                    <td>
                        <p className='fw-normal mb-1'>Titular</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className='d-flex align-items-center'>
                        <img
                            src={userAnon}
                            alt=''
                            style={{ width: '45px', height: '45px' }}
                            className='rounded-circle'
                        />
                        <div className='ms-3'>
                            <p className='fw-bold mb-1'>Paula Tabárez</p>
                        </div>
                        </div>
                    </td>
                    <td>
                        <p className='fw-normal mb-1'>Suplente</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className='d-flex align-items-center'>
                        <img
                            src={userAnon}
                            alt=''
                            style={{ width: '45px', height: '45px' }}
                            className='rounded-circle'
                        />
                        <div className='ms-3'>
                            <p className='fw-bold mb-1'>Maira Malán</p>
                        </div>
                        </div>
                    </td>
                    <td>
                        <p className='fw-normal mb-1'>Suplente</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className='d-flex align-items-center'>
                        <img
                            src={userAnon}
                            alt=''
                            style={{ width: '45px', height: '45px' }}
                            className='rounded-circle'
                        />
                        <div className='ms-3'>
                            <p className='fw-bold mb-1'>Laura Brum</p>
                        </div>
                        </div>
                    </td>
                    <td>
                        <p className='fw-normal mb-1'>Suplente</p>
                    </td>
                </tr>
            </MDBTableBody>
        </MDBTable>
        </MDBTabsPane>
        </MDBTabsContent>
    </MDBContainer>
    <br></br>
    <Footer/>

    </>
  );
}

/*
<h6 className='text-dark my-5'>Comisión Electoral</h6>
        <MDBTable align='middle'>
            <MDBTableHead>
                <tr>
                    <th scope='col'>Nombre</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody>
                <tr>
                    <td>
                        <div className='d-flex align-items-center'>
                        <img
                            src={userAnon}
                            alt=''
                            style={{ width: '45px', height: '45px' }}
                            className='rounded-circle'
                        />
                        <div className='ms-3'>
                            <p className='fw-bold mb-1'>Carina Erchini</p>
                        </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className='d-flex align-items-center'>
                        <img
                            src={userAnon}
                            alt=''
                            style={{ width: '45px', height: '45px' }}
                            className='rounded-circle'
                        />
                        <div className='ms-3'>
                            <p className='fw-bold mb-1'>Marcela Caporale</p>
                        </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className='d-flex align-items-center'>
                        <img
                            src={userAnon}
                            alt=''
                            style={{ width: '45px', height: '45px' }}
                            className='rounded-circle'
                        />
                        <div className='ms-3'>
                            <p className='fw-bold mb-1'>Ana Gamas</p>
                        </div>
                        </div>
                    </td>
                </tr>
            </MDBTableBody>
        </MDBTable>
*/