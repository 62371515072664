import React, { useState } from "react";
import ArquaIcon from "../assets/ArquaIcon.png";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
} from "mdb-react-ui-kit";

export default function App() {
  const [showBasic, setShowBasic] = useState(false);

  /*function toggleDropDown() {
    document.querySelector(".toggleDropDown").click();
  }*/

  return (
    <MDBNavbar expand="lg" className="fixed-top">
      <MDBContainer fluid>
        <MDBNavbarBrand href="/" className="navBrand">
          <img src={ArquaIcon} alt="ArquaIcon" className="ArquaIcon" />
        </MDBNavbarBrand>

        <MDBNavbarToggler
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setShowBasic(!showBasic)}
          className="text-light"
        >
          <MDBIcon icon="bars" fas />
        </MDBNavbarToggler>

        <MDBCollapse navbar show={showBasic}>
          <MDBNavbarNav className="mr-auto mb-2 mb-lg-0 navItems px-5">
          <MDBNavbarItem
            onMouseLeave={() => document.querySelector(".toggleDropDown").click()}
            onMouseEnter={() => document.querySelector(".toggleDropDown").click()}
            className="d-none d-lg-inline"
            >
              <MDBDropdown>
                <MDBDropdownToggle
                   tag="a"
                  className="nav-link toggleDropDown"
                  role="button"
                  
                >
                  Institucional
                </MDBDropdownToggle>
                <MDBDropdownMenu
                  aria-labelledby="dropdownMenuButton"
                  className="menuDrop square border border-1 border-warning"
                  >
                  <MDBDropdownItem link href="/Historia">Historia de la asociación</MDBDropdownItem>
                  <MDBDropdownItem link href="/Estatuto">Estatuto</MDBDropdownItem>
                  <MDBDropdownItem link href="/Comisiones">Comisiones</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>
            <MDBNavbarItem
              onMouseLeave={() => document.querySelector(".toggleDropDown2").click()}
              onMouseEnter={() => document.querySelector(".toggleDropDown2").click()}
              className="d-none d-lg-inline"
            >
              <MDBDropdown>
                <MDBDropdownToggle
                  tag="a"
                  className="nav-link toggleDropDown2"
                  role="button"  
                >
                  Socios
                </MDBDropdownToggle>
                <MDBDropdownMenu
                  aria-labelledby="dropdownMenuButton"
                  className="menuDrop square border border-1 border-warning"
                >
                  <MDBDropdownItem link href="/NuevosSocios">Nuevos Socios</MDBDropdownItem>
                  <MDBDropdownItem link href="/PagoAnualidad">Pago de anualidad</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>

            <MDBNavbarItem
              onMouseLeave={() => document.querySelector(".toggleDropDown3").click()}
              onMouseEnter={() => document.querySelector(".toggleDropDown3").click()}
              className="d-none d-lg-inline"
            >
              <MDBDropdown>
                <MDBDropdownToggle
                  tag="a"
                  className="nav-link toggleDropDown3"
                  role="button"     
                >
                  Novedades
                </MDBDropdownToggle>
                <MDBDropdownMenu
                  aria-labelledby="dropdownMenuButton"
                  className="menuDrop square border border-1 border-warning"
                >
                  <MDBDropdownItem link href="/Construccion">Cursos</MDBDropdownItem>
                  <MDBDropdownItem link href="/Eventos">Eventos</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>
    

            <MDBNavbarItem
            className="d-lg-none"
            >
              <MDBDropdown>
                <MDBDropdownToggle
                   tag="a"
                  className="nav-link toggleDropDown"
                  role="button"
                  
                >
                  Institucional
                </MDBDropdownToggle>
                <MDBDropdownMenu
                  aria-labelledby="dropdownMenuButton"
                  className="menuDrop square border border-1 border-warning"
                  >
                  <MDBDropdownItem link href="/Historia">Historia de la asociación</MDBDropdownItem>
                  <MDBDropdownItem link href="/Estatuto">Estatuto</MDBDropdownItem>
                  <MDBDropdownItem link href="/Comisiones">Comisiones</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>
            <hr className="hr hr-blurry d-inline d-lg-none bg-warning me-5 my-2" />
            <MDBNavbarItem
              className="d-lg-none"
            >
              <MDBDropdown>
                <MDBDropdownToggle
                  tag="a"
                  className="nav-link toggleDropDown2"
                  role="button"  
                >
                  Socios
                </MDBDropdownToggle>
                <MDBDropdownMenu
                  aria-labelledby="dropdownMenuButton"
                  className="menuDrop square border border-1 border-warning"
                >
                  <MDBDropdownItem link href="/NuevosSocios">Nuevos Socios</MDBDropdownItem>
                  <MDBDropdownItem link href="/PagoAnualidad">Pago de anualidad</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>
            <hr className="hr hr-blurry d-inline d-lg-none bg-warning me-5 my-2" />
            <MDBNavbarItem
              className="d-lg-none"
            >
              <MDBDropdown>
                <MDBDropdownToggle
                  tag="a"
                  className="nav-link toggleDropDown3"
                  role="button"     
                >
                  Novedades
                </MDBDropdownToggle>
                <MDBDropdownMenu
                  aria-labelledby="dropdownMenuButton"
                  className="menuDrop square border border-1 border-warning"
                >
                  <MDBDropdownItem link href="/Construccion">Cursos</MDBDropdownItem>
                  <MDBDropdownItem link href="/Eventos">Eventos</MDBDropdownItem>
                  <MDBDropdownItem link href="/Construccion">Noticias</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>
            <hr className="d-inline d-lg-none hr bg-warning hr-blurry me-5  my-2" />
            <MDBNavbarLink href="/Gallery">Galería</MDBNavbarLink>
            <hr className="d-inline d-lg-none hr bg-warning hr-blurry me-5  my-2" />
            <MDBNavbarLink href="/Contact">Contacto</MDBNavbarLink>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  
  );
}

