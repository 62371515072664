import MenuNav from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import madresFamiliares from '../assets/MadresyFamiliares/madresyfamiliares.jpg';
import TramaIndigena from '../assets/TramaIndigena.jpg';
import Antiracismo from '../assets/Antiracismo.jpg';
import Asociarse from '../assets/Asociarse.jpg';
import MdeoIndigena from '../assets/MdeoIndigena.jpg';
import Anual_2019 from '../assets/Anual_2019.png';
import PueblosIndigenas from '../assets/PueblosIndigenas.jpeg'
import { MDBContainer, MDBCol, MDBRow, MDBCarousel, MDBCarouselItem, MDBCard, MDBCardImage, MDBFooter} from 'mdb-react-ui-kit';
//import Divider from '../components/Divider.js';

function Home () {


return (
    <>
    <MenuNav />
    <MDBContainer className='containerHome'> 
        <MDBRow className='d-flex flex-lg-row flex-row justify-content-center' >
            <MDBCol size='8' className='pHome'>
            <MDBCarousel className="" showIndicators showControls fade dealy={3000} style={{height:'500'}}>
                <a href='/Familiares'>
                    <MDBCarouselItem
                        className='w-100 d-block'
                        itemId={1}
                        src={madresFamiliares}
                        alt='...'
                    >
                    </MDBCarouselItem>
                </a>
                <a href='/Eventos'>
                    <MDBCarouselItem
                        className='w-100 d-block'
                        itemId={2}
                        src={TramaIndigena}
                        alt='...'
        
                    >  
                    </MDBCarouselItem>
                </a>
                <a href='/Eventos'>
                    <MDBCarouselItem
                        className='w-100 d-block'
                        itemId={3}
                        src={PueblosIndigenas}
                        alt='...'
                    >  
                    </MDBCarouselItem>
                </a>
                <a href='/Eventos'>
                    <MDBCarouselItem
                        className='w-100 d-block'
                        itemId={4}
                        src={Antiracismo}
                        alt='...'
                    >
                    </MDBCarouselItem>
                </a>
                </MDBCarousel>
            </MDBCol>
        </MDBRow>
    </MDBContainer>    
    <MDBFooter  style={{ backgroundColor: '#F6984E'}} className='mb-5'>
        <MDBRow className='row-cols-1 row-cols-md-3 g-4 mt-4 px-3'>
        <MDBCol size="12" lg="4" className='d-flex align-items-center flex-column'>
                <MDBCard className='mb-3 '  >
                        <MDBCardImage src={Anual_2019} fluid alt='...' style={{height:'250px' }} width='350'/>
                            <a href='/Historia'>
                                <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
                            </a>
                </MDBCard>
                <h6>Sobre nosotros</h6>
            </MDBCol>
            <MDBCol size="12" lg="4" className='d-flex align-items-center flex-column'>
                <MDBCard className=' mb-3 '>
                  
                        <MDBCardImage src={Asociarse} fluid alt='...'  style={{height:'250px'} } width='350'/>
                            <a href='/NuevosSocios'>
                                <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
                            </a>
                </MDBCard>
                <h6>
                    Nuevos socios
                </h6>
            </MDBCol>
            <MDBCol size="12" lg="4" className='d-flex align-items-center flex-column'>
                <MDBCard className='mb-3 ' >
                  
                        <MDBCardImage src={MdeoIndigena} fluid alt='...' style={{height:'250px'}} width='350'/>
                            <a href='/Eventos'>
                                <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
                            </a>
                </MDBCard>
                <h6>Sobre nosotros</h6>
            </MDBCol>
    </MDBRow>
    </MDBFooter>
    <Footer/>
    </>
)
}

export default Home;