import Home from './pages/Home';
import { BrowserRouter, Route,Routes } from "react-router-dom";
import Contact from './pages/Contact';
import Construccion from './pages/Construccion';
import HistoriaAsoc from './pages/HistoriaAsoc';
import Estatuto from './pages/Estatuto';
import Comisiones from './pages/Comisiones';
import NuevosSocios from './pages/NuevosSocios';
import PagoAnualidad from './pages/PagoAnualidad';
import Gallery from './pages/Gallery';
import Panteones from './pages/Panteones';
import Excavaciones from './pages/Excavaciones';
import Eventos from './pages/Eventos';
import ExpoEncuentro from './pages/ExpoEncuentro';
import ConosLavalleja from './pages/ConosLavalleja';
import Condiciones from './pages/Condiciones';
import TramaIndigena from './pages/ConversatorioIndígena';
import Familiares from './pages/Familiares';

import './assets/App.css';




function App() {
  return (
    <div className="App">
      <BrowserRouter basename="/">
         <Routes>
           <Route exact path="/" element={<Home/>} />
           <Route path="Contact" element={<Contact/>} />
           <Route path="Construccion" element={<Construccion/>} />
           <Route path="Historia" element={<HistoriaAsoc/>} />
           <Route path="Estatuto" element={<Estatuto/>} />
           <Route path="Comisiones" element={<Comisiones/>} />
           <Route path="NuevosSocios" element={<NuevosSocios/>} />
           <Route path="PagoAnualidad" element={<PagoAnualidad/>} />
           <Route path="Gallery" element={<Gallery/>} />
           <Route path="Panteones" element={<Panteones/>} />
           <Route path="Excavaciones" element={<Excavaciones/>} />
           <Route path="ExpoEncuentro" element={<ExpoEncuentro/>} />
           <Route path="ConosLavalleja" element={<ConosLavalleja/>} />
           <Route path="Eventos" element={<Eventos/>} />
           <Route path="Condiciones" element={<Condiciones/>} />
           <Route path="TramaIndigena" element={<TramaIndigena/>} />
           <Route path="Familiares" element={<Familiares/>} />
         </Routes>
     </BrowserRouter>
    </div>
  );
}

export default App;
