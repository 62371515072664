import MenuNav from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import { MDBContainer, MDBCol} from 'mdb-react-ui-kit';
import NotadeSolicitud from '../PDF/NotadeSolicitud.docx';

function NuevosSocios() {


    return (
        <>
    <MenuNav />
    <MDBContainer className='containerHome containerHome d-flex flex-column align-items-center text-light '> 
        <MDBCol size="5" className='d-flex align-items-center flex-column'>
                <h4 className='pb-2 text-center text-light'>Asociate a ARQUA</h4>
                <p className="text-focus-in pt-3 text-center">
                    Si estás interesado en asociarte a ARQUA debes enviarnos un email a:
                    <a className="ps-1"href='mailto:arqueologos.uruguay@gmail.com'>arqueologos.uruguay@gmail.com</a> adjuntando este <a href={NotadeSolicitud}>Formulario</a> completo. El mismo debe tener tu firma y la de tres socios.
                </p>
        </MDBCol>
    </MDBContainer>

    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <Footer className='footerHistoria'/>
    </>
        
        
    )
    }
    
    export default NuevosSocios;


    