import MenuNav from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import {MDBContainer, MDBBtn, MDBCol} from 'mdb-react-ui-kit';
import PDF from '../PDF/Acta1.pdf';

function Estatuto () {


    return (
        <>
    <MenuNav />
    <MDBContainer className='containerHome containerHome d-flex flex-column align-items-center '> 
        <MDBCol size="5" className='d-flex align-items-center flex-column'>
                <h4 className='pb-3 text-light text-center'>Estatuto de la Asociación</h4>
                <p className="text-focus-in pt-3 text-center text-light">
                    Dejamos a disposición el estatuto creado en el año 2015 para poder contextualizarte con respecto a la creación de la asociación, como se estructura, y las normas a la hora de participar en la misma.
                </p>
                <MDBBtn className='bg-warning text-light mt-3' a href= {PDF} target="_blank">
                    Click aquí
                </MDBBtn>
        </MDBCol>
    </MDBContainer>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <Footer className='footerHistoria'/>
    </>
        
    )
    }
    
    export default Estatuto;
    