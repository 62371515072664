import React from 'react';
import MenuNav from "../components/Navbar.js"
import {MDBContainer} from "mdb-react-ui-kit";
import Construccion from '../assets/construccion.jpg';
import Footer from '../components/Footer.js';



function Gallery () {


    return (
        <>
    <MenuNav />
    <MDBContainer className='containerHome d-flex flex-column align-items-center'> 
        <h1 className='text-light'>Sección en construcción</h1>
        <img src={Construccion} style={{width: "350px"}} alt=''></img>
    </MDBContainer>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <Footer/>
        </>
    )
    }
    
    export default Gallery;
    