import MenuNav from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import { MDBContainer, MDBCol} from 'mdb-react-ui-kit';


function Condiciones() {


    return (
        <>
    <MenuNav />
    <MDBContainer className='containerHome containerHome d-flex flex-column align-items-center text-light '> 
        <MDBCol size="8" className='d-flex align-items-start flex-column'>
                <h4 className='pb-2 text-center text-light'>Política de Privacidad y utilización de Cookies.</h4>
                <p className="text-focus-in pt-3 small">
                    En ARQUA sabemos que la privacidad de nuestros lectores/usuarios/anunciantes es importante, por lo que la »Política de Privacidad» de ARQUA se utiliza para que los lectores/usuarios/anunciantes del sitio conozcan como recogemos, guardamos o utilizamos la información que recabamos a través de los diferentes servicios que se ofrecen y/o páginas disponibles en este sitio.
                </p>
                <p className="text-focus-in pt-3 small">
                    Por favor, lea el siguiente documento para conocer mejor cómo manejamos la información, incluido qué tipo de datos recabamos, cómo los usamos y con qué fin, a quién se los facilitamos y qué hacemos para proteger su información personal. Recomendamos también tener presente las Políticas de Privacidad de Google, ya que indirectamente el acceso a ARQUA implica la aceptación de las mismas, en todos sus términos y condiciones.
                </p>
                <p className="text-focus-in pt-3 small">
                1.1. Los usuarios aceptan y reconocen que la  publicidad brindada por los anunciantes de ARQUA  puede conllevar la utilización de Cookies y Web Beacons para obtener información, tanto en el sitio arqua.com.uy, como en los sitios de los respectivos anunciantes.
                </p>
                <p className="text-focus-in pt-3 small">
                    1.2. COOKIES, WEB BEACONS COOKIE DART Y OTRAS <br></br>
                    TECNOLOGÍAS:<br></br>
                    Como es práctica habitual en muchos sitios web, arqua.com.uy utiliza »cookies» y otras tecnologías para ayudar a comprender qué partes de nuestros sitios web tienen más aceptación, dónde van nuestros visitantes, personalizar el contenido y los anuncios, ofrecer funciones de redes sociales y analizar el tráfico. Además, compartimos información sobre el uso que haga del sitio web con nuestros partners de redes sociales, publicidad y análisis web, quienes pueden combinarla con otra información que les haya proporcionado o que hayan recopilado a partir del uso que haya hecho de sus servicios.
                </p>
                <h6>¿Por qué es importante?</h6>
                <p className="text-focus-in pt-3 small">
                    La legislación europea exige que los editores de contenido digital informen a los usuarios de sus sitios sobre el uso de cookies y otras formas de almacenamiento local de información. En muchos casos estas leyes también les obligan a obtener el consentimiento expreso de los usuarios.
                </p>
                <p className="text-focus-in pt-3 small">
                    1.3. arqua.com.uy también puede utilizar cookies para registrar sus visitas a nuestro sitio Web. Podemos recopilar información, incluida la dirección IP, como identificación personal. Sin embargo, esta información sólo se utilizará para fines analíticos y se restringirá al mínimo necesario para llevar a cabo el análisis.
                </p>
                <p className="text-focus-in pt-3 small">
                    Si no desea que arqua.com.uy despliegue cookies en su navegador, puede configurarlo para que rechace las cookies o para que le informe cuándo un sitio Web intenta colocar una cookie en el software del navegador.
                </p>
                <p className="text-focus-in pt-3 small">
                    La mayoría de los navegadores permiten configurar el manejo de cookies de las siguientes 3 formas:<br></br><br></br>
                    * Rechazar todas las cookies,<br></br>
                    * Aceptar todas las cookies,<br></br>
                    * Optar por aceptar o rechazar determinadas cookies.<br></br>
                </p>
                <p className="text-focus-in pt-3 small">
                    El navegador también puede permitir especificar mejor qué cookies tienen que ser aceptadas y cuáles no. En concreto, el usuario puede normalmente aceptar alguna de las siguientes opcines: rechazar las cookies de determinados dominios; rechazar las cookies de terceros; aceptar cookies como no persistentes (se eliminan cuando el navegador se cierra); permitir al servidor crear cookies para un dominio diferente. Además, los navegadores pueden también permitir a los usuarios ver y borrar cookies individualmente.
                </p>
                <p className="text-focus-in pt-3 small">
                  1.4. arqua.com.uy utilizara Cookies de análisis: Son aquéllas que bien tratadas por Search Console de Google , nos permiten cuantificar el número de usuarios y realizar la medición y análisis estadístico de la utilización que hacen los usuarios de nuestra web. Para ello analizamos la navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que ofrecemos. 
                </p>
                <h6>
                    2. Otros aspectos sobre la privacidad.
                </h6>
                <p className="text-focus-in pt-3 small">
                    2.1. Si utiliza los comentarios/trackbacks o publicas post en arqua.com.uy, debes tener en cuenta que cualquier información que publiques estará a disposición del resto de los lectores/usuarios/anunciantes. La información personal distintiva que envíes a estos puede ser leída, recopilada o utilizada por otros lectores/usuarios/anunciantes.
                </p>
                <p className="text-focus-in pt-3 small">
                    2.2. arqua.com.uy no se hace responsable de la información personal que decidas publicar, esta se considera de carácter público y no confidencial.
                </p>
                <p className="text-focus-in pt-3 small">
                    2.3. En algunos casos, arqua.com.uy comparte información sobre los visitantes de este sitio de forma anónima o agregada con terceros como puedan ser anunciantes, patrocinadores o auditores con el único fin de mejorar nuestros servicios. Todas estas tareas de procesamiento serán reguladas según las normas legales y se respetarán todos sus derechos en materia de protección de datos conforme a la regulación vigente.
                </p>
                <p className="text-focus-in pt-3 small">
                    2.4. Este sitio también puede albergar publicidad propia, de afiliados, o de redes publicitarias. Esta publicidad se muestra mediante servidores publicitarios que también utilizan cookies para mostrar contenidos publicitarios afines a los usuarios. Cada uno de estos servidores publicitarios dispone de su propia política de privacidad, que puede ser consultada en sus propias páginas web.2.4. Este sitio también puede albergar publicidad propia, de afiliados, o de redes publicitarias. Esta publicidad se muestra mediante servidores publicitarios que también utilizan cookies para mostrar contenidos publicitarios afines a los usuarios. Cada uno de estos servidores publicitarios dispone de su propia política de privacidad, que puede ser consultada en sus propias páginas web.
                </p>
                <p className="text-focus-in pt-3 small">
                    2.5. arqua.com.uy No divulgara ni venderá ningun dato como email, teléfono, C.I., DNIC, etc que sea alojado en uno de nuestros servidores.
                </p>
                <h6 className="text-focus-in pt-3 small">
                    3. Licencia del contenido.
                </h6>
                <p className="text-focus-in pt-3 small">
                   3.1. arqua.com.uy se encuentra bajo Licencia Creative Commons, dicha licencia permite la utilización de los contenidos (textos) con fines no comerciales, exigiendo sólo la atribución al autor con un enlace directo hacia la entrada original.
                </p>
                <p className="text-focus-in pt-3 small">
                    3.2. arqua.com.uy no se atribuye ningún tipo de derecho de propiedad sobre ninguna de las imágenes mostradas en el sitio, ni ningún otro tipo de material que no sean los textos citados en el punto anterior (4.1)
                </p>
                <h6>
                    Cambios en la política.
                </h6>
                <p className="text-focus-in pt-3 small">
                    4.1. arqua.com.uy se reserva el derecho a modificar total o parcialmente estas condiciones cuando lo estime necesario sin previo aviso. En el caso de que se implemente un cambio importante en las Políticas de Privacidad, se publicará un aviso en nuestro Sitio Web.
                </p>
                <h6>
                    Contacto
                </h6>
                <p className="text-focus-in pt-3 small">
                    Por cuestiones relacionadas con la privacidad, derechos de autor o las políticas del Sitio, usted puede contactarse con los responsables de arqua por medio del formulario de <a href='/Contact'>Contacto</a>.
                </p>
        </MDBCol>
    </MDBContainer>

    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <Footer className='footerHistoria'/>
    </>
        
        
    )
    }
    
    export default Condiciones;
