import MenuNav from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import Anual_2019 from '../assets/Anual_2019.png';
import { MDBContainer} from 'mdb-react-ui-kit';
//import Divider from '../components/Divider.js';

function HistoriaAsoc () {


return (
    <>
    <MenuNav />
    <MDBContainer className='containerHome d-flex flex-column justify-content-center align-items-center'> 
                <h4 className='pb-3 text-light'>Historia de la Asociación</h4>
                <img src={Anual_2019} className="rounded-2 shadow-4 float-none mb-5" style={{width: "400px"}}  alt=''/>
                <p className="text-focus-in text-light">
                    En mayo del 2014 un grupo de arqueólogas y arqueólogos comenzaron a trabajar en forma colectiva con el objetivo de fundar una asociación que nucleara a los profesionales en Arqueología de Uruguay.
                    Luego de un año de trabajo, el 24 de julio de 2015 Arqueólogos del Uruguay Asociados (Arqua) se constituyó formalmente, cuando se llevó a cabo la Asamblea Fundacional. La concurrida asistencia de colegas fundadores puso de manifiesto y reafirmó la importancia de contar con una asociación, así como cubrir el vacío dejado ante la disolución de la Asociación Uruguaya de Arqueología (AUA).
                    Arqua nuclea  arqueólogas y arqueólogos  especializados en distintas áreas y campos de acción, que provienen de ámbitos laborales diversos, pero que comparten las mismas metas preocupaciones e intereses acerca del Patrimonio Arqueológico uruguayo.
                </p>        
    </MDBContainer>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <Footer className='footerHistoria'/>
    </>
)
}

export default HistoriaAsoc;