import MenuNav from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import { MDBContainer, MDBCard, MDBCardImage, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import madresFamiliares2 from '../assets/MadresyFamiliares/madresyfamiliares2.JPG';
import madresFamiliares3 from '../assets/MadresyFamiliares/madresyfamiliares3.JPG';
import madresFamiliares4 from '../assets/MadresyFamiliares/madresyfamiliares4.JPG';
import madresFamiliares5 from '../assets/MadresyFamiliares/madresyfamiliares5.JPG';
import madresFamiliares6 from '../assets/MadresyFamiliares/madresyfamiliares6.JPG';
import madresFamiliares7 from '../assets/MadresyFamiliares/madresyfamiliares7.JPG';
import madresFamiliares8 from '../assets/MadresyFamiliares/madresyfamiliares8.JPG';

function Familiares () {


    return (
        <>
    <MenuNav />
    <MDBContainer className='containerHome containerHome d-flex flex-column align-items-center '>
    <h4 className="text-focus-in pt-3 text-center text-light mb-5">
        Día Internacional del Detenido Desaparecido 2023<br></br>
    </h4>
    <p className='text-light'>Ordoras: Magdalena Broquetas, Ximena Salvo y Natalia Azziz</p>
    <iframe className='mb-5' width="560" height="315" src="https://www.youtube.com/embed/SDgjvjCzHig?si=7pw5_CPZEYgtF5xL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <MDBRow className='row-cols-1 row-cols-md-3 g-4'>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={madresFamiliares2} alt='...' position='top' className='rounded-0'/>
          <a href={madresFamiliares2} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={madresFamiliares3} alt='...' position='top' className='rounded-0'/>
          <a href={madresFamiliares3} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={madresFamiliares4} alt='...' position='top' className='rounded-0'/>
          <a href={madresFamiliares4} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={madresFamiliares5} alt='...' position='top' className='rounded-0'/>
          <a href={madresFamiliares5} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={madresFamiliares6} alt='...' position='top' className='rounded-0'/>
          <a href={madresFamiliares6} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={madresFamiliares7} alt='...' position='top' className='rounded-0'/>
          <a href={madresFamiliares7} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={madresFamiliares8} alt='...' position='top' className='rounded-0'/>
          <a href={madresFamiliares8} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
    </MDBRow>
    </MDBContainer>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <Footer className='footerHistoria'/>
    </>
        
    )
    }
    
    export default Familiares;