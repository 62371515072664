import MenuNav from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import { MDBContainer, MDBCol} from 'mdb-react-ui-kit';

function PagoAnualidad() {


    return (
      <>
    <MenuNav />
    <MDBContainer className='containerHome containerHome d-flex flex-column align-items-center text-light'> 
        <MDBCol size="5" className='d-flex align-items-center flex-column'>
                <h4 className='pb-2 text-center text-light'>Pago de anulidad</h4>
                <p className="text-focus-in pt-3 text-center">
                    Recordamos a nuestros asociad@s que el valor de la cuota es de 1 UR ANUAL y puede realizarse en un único pago o hasta en tres cuotas.<br></br>
                    Por cualquier consulta dirigirse a:<br></br>
                    <a href='mailto:tesoreria.arqua@gmail.com'>tesoreria.arqua@gmail.com</a>
                </p>
        </MDBCol>
    </MDBContainer>

    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <Footer className='footerHistoria'/>
    </>
        
        
    )
    }
    
    export default PagoAnualidad;

    