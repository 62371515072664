import MenuNav from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import { MDBContainer, MDBCard, MDBCardImage, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import ExpoEncuentro1 from '../assets/ExpoEncuentro1.jpg'
import ExpoEncuentro2 from '../assets/ExpoEncuentro2.jpg'
import ExpoEncuentro3 from '../assets/ExpoEncuentro3.jpg'
import ExpoEncuentro4 from '../assets/ExpoEncuentro4.jpg'
import ExpoEncuentro5 from '../assets/ExpoEncuentro5.jpg'
import ExpoEncuentro6 from '../assets/ExpoEncuentro6.jpg'
import ExpoEncuentro7 from '../assets/ExpoEncuentro7.jpg'
import ExpoEncuentro8 from '../assets/ExpoEncuentro8.jpg'
import ExpoEncuentro9 from '../assets/ExpoEncuentro9.jpg'
import ExpoEncuentro10 from '../assets/ExpoEncuentro10.jpg'
import ExpoEncuentro11 from '../assets/ExpoEncuentro11.jpg'



function ExpoEncuentro () {


    return (
        <>
    <MenuNav />
    <MDBContainer className='containerHome containerHome d-flex flex-column align-items-center '>
    <h4 className="text-focus-in pt-3 text-center text-light mb-5">
        Primer encuentro arquelógico del Uruguay<br></br>
    </h4>
    <MDBRow className='row-cols-1 row-cols-md-3 g-4'>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={ExpoEncuentro1} alt='...' position='top' className='rounded-0'/>
          <a href={ExpoEncuentro1} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={ExpoEncuentro2} alt='...' position='top' className='rounded-0'/>
          <a href={ExpoEncuentro2} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={ExpoEncuentro3} alt='...' position='top' className='rounded-0'/>
          <a href={ExpoEncuentro3} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={ExpoEncuentro4} alt='...' position='top' className='rounded-0'/>
          <a href={ExpoEncuentro4} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={ExpoEncuentro5} alt='...' position='top' className='rounded-0'/>
          <a href={ExpoEncuentro5} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={ExpoEncuentro6} alt='...' position='top' className='rounded-0'/>
          <a href={ExpoEncuentro6} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={ExpoEncuentro6} alt='...' position='top' className='rounded-0'/>
          <a href={ExpoEncuentro6} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={ExpoEncuentro7} alt='...' position='top' className='rounded-0'/>
          <a href={ExpoEncuentro7} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={ExpoEncuentro8} alt='...' position='top' className='rounded-0'/>
          <a href={ExpoEncuentro8} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={ExpoEncuentro9} alt='...' position='top' className='rounded-0'/>
          <a href={ExpoEncuentro9} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={ExpoEncuentro10} alt='...' position='top' className='rounded-0'/>
          <a href={ExpoEncuentro10} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={ExpoEncuentro11} alt='...' position='top' className='rounded-0'/>
          <a href={ExpoEncuentro11} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
    </MDBRow>
    </MDBContainer>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <Footer className='footerHistoria'/>
    </>
        
    )
    }
    
    export default ExpoEncuentro;