import MenuNav from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import { MDBContainer, MDBCard, MDBCardImage, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import Conversatorio1 from '../assets/ConversatorioIndigena/Conversatorio1.jpeg';
import Conversatorio2 from '../assets/ConversatorioIndigena/Conversatorio2.jpeg';
import Conversatorio3 from '../assets/ConversatorioIndigena/Conversatorio3.jpeg';
import Conversatorio4 from '../assets/ConversatorioIndigena/Conversatorio4.jpeg';
import Conversatorio5 from '../assets/ConversatorioIndigena/Conversatorio5.jpeg';
import Conversatorio6 from '../assets/ConversatorioIndigena/Conversatorio6.jpeg';
import Conversatorio7 from '../assets/ConversatorioIndigena/Conversatorio7.jpeg';
import Conversatorio8 from '../assets/ConversatorioIndigena/Conversatorio8.jpeg';
import Conversatorio9 from '../assets/ConversatorioIndigena/Conversatorio9.jpeg';
import Conversatorio10 from '../assets/ConversatorioIndigena/Conversatorio10.jpeg';


function TramaIndigena () {

return (
    <>
<MenuNav />
<MDBContainer className='containerHome containerHome d-flex flex-column align-items-center '>
<h4 className="text-focus-in pt-3 text-center text-light mb-4">
    Conversatorio<br></br>
</h4>
<p className='text-light mb-5'>
    Visibilizando la trama indígena. El trauma social como simiento de la nación.
</p>
<MDBRow className='row-cols-1 row-cols-md-3 g-4'>
  <MDBCol>
    <MDBCard className='rounded-0 hover-overlay'>
      <MDBCardImage src={Conversatorio1} alt='...' position='top' className='rounded-0'/>
      <a href={Conversatorio1} alt='' rel="noreferrer">
        <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
      </a>
    </MDBCard>
  </MDBCol>
  <MDBCol>
    <MDBCard className='rounded-0 hover-overlay'>
      <MDBCardImage src={Conversatorio2} alt='...' position='top' className='rounded-0'/>
      <a href={Conversatorio2} alt='' rel="noreferrer">
        <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
      </a>
    </MDBCard>
  </MDBCol>
  <MDBCol>
    <MDBCard className='rounded-0 hover-overlay'>
      <MDBCardImage src={Conversatorio3} alt='...' position='top' className='rounded-0'/>
      <a href={Conversatorio3} alt='' rel="noreferrer">
        <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
      </a>
    </MDBCard>
  </MDBCol>
  <MDBCol>
    <MDBCard className='rounded-0 hover-overlay'>
      <MDBCardImage src={Conversatorio4} alt='...' position='top' className='rounded-0'/>
      <a href={Conversatorio4} alt='' rel="noreferrer">
        <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
      </a>
    </MDBCard>
  </MDBCol>
  <MDBCol>
    <MDBCard className='rounded-0 hover-overlay'>
      <MDBCardImage src={Conversatorio5} alt='...' position='top' className='rounded-0'/>
      <a href={Conversatorio5} alt='' rel="noreferrer">
        <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
      </a>
    </MDBCard>
  </MDBCol>
  <MDBCol>
    <MDBCard className='rounded-0 hover-overlay'>
      <MDBCardImage src={Conversatorio6} alt='...' position='top' className='rounded-0'/>
      <a href={Conversatorio6} alt='' rel="noreferrer">
        <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
      </a>
    </MDBCard>
  </MDBCol>
  <MDBCol>
  <MDBCol>
    <MDBCard className='rounded-0 hover-overlay mb-4'>
      <MDBCardImage src={Conversatorio9} alt='...' position='top' className='rounded-0'/>
      <a href={Conversatorio9} alt='' rel="noreferrer">
        <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
      </a>
    </MDBCard>
  </MDBCol>
    <MDBCard className='rounded-0 hover-overlay'>
      <MDBCardImage src={Conversatorio7} alt='...' position='top' className='rounded-0'/>
      <a href={Conversatorio7} alt='' rel="noreferrer">
        <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
      </a>
    </MDBCard>
  </MDBCol>
  <MDBCol>
    <MDBCard className='rounded-0 hover-overlay'>
      <MDBCardImage src={Conversatorio10} alt='...' position='top' className='rounded-0'/>
      <a href={Conversatorio10} alt='' rel="noreferrer">
        <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
      </a>
    </MDBCard>
  </MDBCol>
  <MDBCol>
    <MDBCard className='rounded-0 hover-overlay'>
      <MDBCardImage src={Conversatorio8} alt='...' position='top' className='rounded-0'/>
      <a href={Conversatorio8} alt='' rel="noreferrer">
        <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
      </a>
    </MDBCard>
  </MDBCol>
</MDBRow>
</MDBContainer>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<Footer className='footerHistoria'/>
</>
    
)
}

export default TramaIndigena;