import MenuNav from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import {MDBContainer,MDBInput, MDBBtn, MDBIcon} from "mdb-react-ui-kit";
import React, {useRef} from 'react';
import emailjs from '@emailjs/browser';
import swal from 'sweetalert';

function Contact () {

const mostrarAlert = () => { 
        swal({
            title: "Tu mensaje fue enviado",
            icon: "success",
            button: "Cerrar"
        })
    }

const form = useRef();


    const sendEmail = (e) => {
      e.preventDefault();

      
  
      emailjs.sendForm('service_iggvhok', 'template_tadvhxi', form.current, 'rcUdnSkk1W7ZD2Wgn')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });

          mostrarAlert();
          
          setTimeout(() => {
            window.location.replace('https://arqua.com.uy/Contact')
          }, 2000);
        ;
    };
  

    return (
        <>
        <MenuNav />
        <MDBContainer className='containerHome text-light'>
        <section className='mb-4 d-flex flex-column justify-content-center align-items-center'>
            <h2 className="font-weight-bold text-center my-4">Contacto</h2>
            <p className="text-center w-responsive mx-auto mb-5">¿Tienes preguntas o algo para plantear?<br></br>
            No dudes en ponerte en contacto con nosotros a través de nuestro formulario o por nuestras redes sociales.</p>
            <form className='d-flex flex-column' ref={form} onSubmit={sendEmail}>
                <MDBInput id='form4Example1' className="bg-light" wrapperClass='mb-4' label='Name' name="user_name"/>
                <MDBInput type='email' id='form4Example2' className="bg-light" wrapperClass='mb-4' label='Email address' name="user_email"/>
                <textarea wrapperClass='mb-4' textarea="true" id='form4Example3' label='Message' name="message" className='textMesagge mb-4 border border-1 border-secondary'/>
                <MDBBtn type='submit' className='mb-4 bg-warning text-dark square border border-1 border-secondary align-self-center' onClick={() => mostrarAlert()}>
                    Enviar
                </MDBBtn>
            </form>   
        </section>
        <section className='mb-4 d-flex flex-row p-2 justify-content-center'>
              
               
                <a href='https://www.instagram.com/arqua.difusion/?igshid=MzRlODBiNWFlZA%3D%3D' className='text-light  d-inline px-4 d-flex flex-column align-items-center'>
                 <MDBIcon fab icon="instagram" size="2x"/>
                    <p className='align-self-center'>Instagram</p>
                </a>
                <a href='https://www.youtube.com/@arqua-arqueologxsuruguay9425/about' className='text-light d-inline px-4 d-flex flex-column align-items-center'>
                 <MDBIcon fab icon="youtube" size="2x" />
                    <p className='align-self-center'>Youtube</p>
                </a>
             
        </section>
        </MDBContainer>
        <Footer/>
        </>
    )
    }
    
    export default Contact;



