import MenuNav from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import { MDBContainer, MDBCard, MDBCardImage, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import Excavacion1 from '../assets/Excavacion1.jpg';
import Excavacion2 from '../assets/Excavacion2.jpg';
import Excavacion3 from '../assets/Excavacion3.jpg';



function Excavaciones () {


    return (
        <>
    <MenuNav />
    <MDBContainer className='containerHome containerHome d-flex flex-column align-items-center '>
    <h4 className="text-focus-in pt-3 text-center text-light mb-5">
        Diversas Excavaciones<br></br>
    </h4>
    <MDBRow className='row-cols-1 row-cols-md-3 g-4'>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={Excavacion1} alt='...' position='top' className='rounded-0'/>
          <a href={Excavacion1} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={Excavacion2} alt='...' position='top' className='rounded-0'/>
          <a href={Excavacion2} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay'>
          <MDBCardImage src={Excavacion3} alt='...' position='top' className='rounded-0'/>
          <a href={Excavacion3} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
    </MDBRow>
    </MDBContainer>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <Footer className='footerHistoria'/>
    </>
        
    )
    }
    
    export default Excavaciones;
    