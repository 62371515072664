import MenuNav from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import { MDBContainer, MDBRipple, MDBCard, MDBCardImage, MDBCardBody, MDBCardTitle, MDBCardText, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import Panteones_01 from '../assets/Panteones_01.jpg';
import Excavacion1 from '../assets/Excavacion1.jpg';
import EncuentroPre from '../assets/ExpoEncuentroPre.jpg';
import ConosLavalleja1 from '../assets/ConosLavalleja.jpg';
import TramaIndigena from '../assets/TramaIndigena.jpg';
import madresFamiliares from '../assets/MadresyFamiliares/madresyfamiliares.jpg';

function Gallery () {


return (
    <>
    <MenuNav />
    <MDBContainer className='containerHome containerHome d-flex flex-column align-items-center'> 
        <MDBRow>
        <MDBCol size="12" lg="5" className='d-flex align-items-center flex-column'>
                <MDBCard className='w-75 mb-3 cardSize' style={{ height:'350px' }}>
                    <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                        <MDBCardImage src={TramaIndigena} fluid alt='...'/>
                            <a href='/TramaIndigena'>
                                <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
                            </a>
                    </MDBRipple>
                    <MDBCardBody>
                        <MDBCardTitle className='small'>Conversatorio</MDBCardTitle>
                        <MDBCardText className='small'>
                                Visibilizando la trama indígena. El trauma social como simiento de la nación.
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
            <MDBCol size="12" lg="5" className='d-flex align-items-center flex-column'>
                <MDBCard className='w-75 mb-3 cardSize' style={{ height:'350px' }}>
                    <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                        <MDBCardImage src={EncuentroPre} fluid alt='...'/>
                            <a href='/ExpoEncuentro'>
                                <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
                            </a>
                    </MDBRipple>
                    <MDBCardBody>
                        <MDBCardTitle className='small'>El futuro tiene pasado</MDBCardTitle>
                        <MDBCardText className='small'>
                                Primer encuentro arqueológico del Uruguay.
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol> 
        </MDBRow>
        <MDBRow>
        <MDBCol size="12" lg="5" className='d-flex align-items-center flex-column'>
                <MDBCard className='w-75 mb-3 cardSize' style={{ height:'350px' }}>
                    <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay' >
                        <MDBCardImage src={ConosLavalleja1} fluid alt='...' />
                            <a href='/ConosLavalleja'>
                                <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                            </a>
                    </MDBRipple>
                    <MDBCardBody>
                        <MDBCardTitle className='small'>Proyecto: “Conos de piedra de Lavalleja”</MDBCardTitle>
                        <MDBCardText className='small'>
                            Responsable: Elena Saccone <br></br>
                            Técnica - Liquenometría
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol> 
            <MDBCol size="12" lg="5" className='d-flex align-items-center flex-column'>
                <MDBCard className='w-75 mb-3 cardSize' style={{ height:'350px' }}>
                    <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                        <MDBCardImage src={Panteones_01} fluid alt='...'/>
                            <a href='/Panteones'>
                                <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
                            </a>
                    </MDBRipple>
                    <MDBCardBody>
                        <MDBCardTitle className='small'>Panteones en Cerro Largo</MDBCardTitle>
                        <MDBCardText className='small'>
                            Elena Saccone  
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>  
        </MDBRow>
        <MDBRow>
            <MDBCol size="12" lg="5" className='d-flex align-items-center flex-column'>
                <MDBCard className='w-75 mb-3 cardSize' style={{ height:'350px' }}>
                    <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay' >
                        <MDBCardImage src={Excavacion1} fluid alt='...' />
                            <a href='/Excavaciones'>
                                <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                            </a>
                    </MDBRipple>
                    <MDBCardBody>
                        <MDBCardTitle className='small'>Diversos trabajos</MDBCardTitle>
                        <MDBCardText className='small'>
                            Showcase de diversas excavaciones
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
            <MDBCol size="12" lg="5" className='d-flex align-items-center flex-column'>
                <MDBCard className='w-75 mb-3 cardSize' style={{ height:'350px' }}>
                    <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay' >
                        <MDBCardImage src={madresFamiliares} fluid alt='...' />
                            <a href='/Familiares'>
                                <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                            </a>
                    </MDBRipple>
                    <MDBCardBody>
                        <MDBCardTitle className='small'>Madres y Familiares de Detenidos Desaparecidos</MDBCardTitle>
                        <MDBCardText className='small'>
                            Día Internacional del Detenido Desaparecido 2023
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>    
        </MDBRow>

    </MDBContainer>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <Footer className='footerHistoria'/>
    </>
)
}

export default Gallery;

/*<MDBContainer className='containerHome'>
<MDBRow>
    <MDBCol size="12" lg="5" className='d-flex'>
        <MDBCard className='w-75 mb-3'>
            <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                <MDBCardImage src={Asociarse} fluid alt='...'/>
                    <a href='/Construccion'>
                        <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                    </a>
            </MDBRipple>
            <MDBCardBody>
                <MDBCardTitle>Galería 1</MDBCardTitle>
                <MDBCardText>
                    Texto explicativo para la galería
                </MDBCardText>
                <MDBBtn href='/Construccion' className='bg-warning text-dark'>Button</MDBBtn>
            </MDBCardBody>
        </MDBCard>
    </MDBCol>
    <MDBCol size="12" lg="5">
        <MDBCard className='w-75 mb-3'>
            <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                <MDBCardImage src={Asociarse} fluid alt='...' />
                    <a href='/Construccion'>
                        <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                    </a>
            </MDBRipple>
            <MDBCardBody>
                <MDBCardTitle>Galería 2</MDBCardTitle>
                <MDBCardText>
                    Texto explicativo para la galería
                </MDBCardText>
                <MDBBtn href='/Construccion' className='bg-warning text-dark'>Button</MDBBtn>
            </MDBCardBody>
        </MDBCard>
    </MDBCol>    
</MDBRow>
<MDBRow>
    <MDBCol size="12" lg="5">
        <MDBCard className='w-75 mb-3'>
            <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                <MDBCardImage src={Asociarse} fluid alt='...' />
                    <a href='/Construccion'>
                        <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                    </a>
            </MDBRipple>
            <MDBCardBody>
                <MDBCardTitle>Galería 3</MDBCardTitle>
                <MDBCardText>
                    Texto explicativo para la galería
                </MDBCardText>
                <MDBBtn href='/Construccion' className='bg-warning text-dark'>Button</MDBBtn>
            </MDBCardBody>
        </MDBCard>
    </MDBCol>
    <MDBCol size="12" lg="5">
        <MDBCard className='w-75 mb-3'>
            <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                <MDBCardImage src={Asociarse} fluid alt='...' />
                    <a href='/Construccion'>
                        <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                    </a>
            </MDBRipple>
            <MDBCardBody>
                <MDBCardTitle>Galería 4</MDBCardTitle>
                <MDBCardText>
                    Texto explicativo para la galería
                </MDBCardText>
                <MDBBtn href='/Construccion' className='bg-warning text-dark'>Button</MDBBtn>
            </MDBCardBody>
        </MDBCard>
    </MDBCol>    
</MDBRow>   
</MDBContainer>*/