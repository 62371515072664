import MenuNav from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import { MDBContainer, MDBCard, MDBCardImage, MDBCol} from 'mdb-react-ui-kit';
import TramaIndigena from '../assets/TramaIndigena.jpg';
import Antiracismo from '../assets/Antiracismo.jpg';
import OrgulloLGTB from "../assets/OrgulloLGTBITQ.JPG";
import Afrodes from "../assets/Afrodes.jpg";
import Museo from '../assets/Museo.jpg';
import MdeoIndigena from '../assets/MdeoIndigena.jpg';
import EncuentroPre from '../assets/ExpoEncuentroPre.jpg';
import PueblosIndigenas from '../assets/PueblosIndigenas.jpeg';



function Eventos () {


    return (
        <>
    <MenuNav />
    <MDBContainer className='containerHome containerHome d-flex flex-column align-items-center'>
      <h4 className="text-focus-in pt-3 text-center text-light mb-5">
          Eventos<br></br>
      </h4>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay mb-3' >
          <MDBCardImage src={PueblosIndigenas} alt='...' position='top' className='rounded-0' style={{ width:'400px' }}/>
          <a href={TramaIndigena} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <p className='text-light small text-center'> Hoy se celebra el Día Internacional de los Pueblos Indígenas. Conmemorado desde el año 1994,  <br></br>
      busca poner en la agenda el reconocimiento y los derechos de los<br></br>
      grupos indígenas, históricamente discriminados y minorizados.<br></br>
      ARQUA se suma a la conmemoración y reafirma el compromiso disciplinar en colaborar con la visibilización de estos pueblos,<br></br>
      su pasado y su presente.</p>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay mb-3' >
          <MDBCardImage src={TramaIndigena} alt='...' position='top' className='rounded-0' style={{ width:'400px' }}/>
          <a href={TramaIndigena} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <p className='text-light'>Conversatorio. "Visibilizando la trama indígena. El trauma social como conocimiento de una nación"</p>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay mb-3 mt-5' >
          <MDBCardImage src={Antiracismo} alt='...' position='top' className='rounded-0' style={{ width:'400px' }}/>
          <a href={Antiracismo} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <p className='text-light small text-center'>Antiracismo, antropología y arqueología en el Uruguay. Miércoles 26 de julio. 18 horas.</p>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay mb-3 mt-5' >
          <MDBCardImage src={Afrodes} alt='...' position='top' className='rounded-0' style={{ width:'400px' }}/>
          <a href={Afrodes} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <p className='text-light small text-center'>"Destacamos el compromiso de una arquelogía y antropología antiracista..."</p>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay mb-3 mt-5' >
          <MDBCardImage src={EncuentroPre} alt='...' position='top' className='rounded-0' style={{ width:'400px' }}/>
          <a href={EncuentroPre} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <p className='text-light small text-center'>El futuro tiene pasado. Primer encuentro arquelógico del Uruguay<br></br>
      26 y 27 de Marzo Casa de la Cultura de Mercedes, Soriano.</p>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay mb-3 mt-5' >
          <MDBCardImage src={OrgulloLGTB} alt='...' position='top' className='rounded-0' style={{ width:'400px' }}/>
          <a href={OrgulloLGTB} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <p className='text-light small text-center'>Deconstruir, cuestionar, reformular, descubrir, construir.</p>
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay mb-3 mt-5' >
          <MDBCardImage src={Museo} alt='...' position='top' className='rounded-0' style={{ width:'400px' }}/>
          <a href={Museo} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
        <p className='text-light small text-center'>Este día Internacional de los Museos nos invita a <br></br>
        reflexionar sobre “Museos, sostenibilidad y bienestar”.<br></br>
        Saludamos especialmente a todos los funcionarios<br></br> y colegas y 
        agradecemos su arduo trabajo en esta tarea. </p>
      </MDBCol>
    
      <MDBCol>
        <MDBCard className='rounded-0 hover-overlay mb-3 mt-5' >
          <MDBCardImage src={MdeoIndigena} alt='...' position='top' className='rounded-0' style={{ width:'400px' }}/>
          <a href={MdeoIndigena} alt='' rel="noreferrer">
            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)'}}></div>
          </a>
        </MDBCard>
      </MDBCol>
      <p className='text-light small text-center'>Este día Internacional de los Museos nos invita a reflexionar <br></br>
      sobre “Museos, sostenibilidad y bienestar” 🌿🌿 <br></br>
      Saludamos especialmente a todos los funcionarios y colegas y agradecemos <br></br> 
      su arduo trabajo en esta tarea. Los invitamos a participar <br></br>
      de las actividades que los museos de nuestro país llevan a cabo.</p>
    </MDBContainer>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <Footer className='footerHistoria'/>
    </>
        
    )
    }
    
    export default Eventos;